.almarai {
  font-family: "Almarai", sans-serif;
}
.anony {
  font-family: "Anonymous Pro", monospace;
}

.ruqaa {
  font-family: "Aref Ruqaa", serif;
}

.notokufi {
  font-family: "Noto Kufi Arabic", sans-serif;
}
.reemkufi {
  font-family: "Reem Kufi", sans-serif;
}

.teko {
  font-family: "Teko", sans-serif;
}
